import { deleteApiWrapper, getApiWrapper, postApiWrapper } from './token-wrapper-function';

export async function getMatrimonialProfiles({ node_id }) {
  const result = await getApiWrapper(`/api/matrimony/profiles_for/${node_id}`);
  console.log('Result from getMatrimonialProfiles ', result);
  return result;
}
export async function getAllMatrimonialProfiles() {
  const result = await getApiWrapper(`/api/matrimony/profile`);
  console.debug('Result from getAllMatrimonialProfiles ', result);
  return result;
}

export async function getEligibleFamilyMembers({ node_id }) {
  const result = await getApiWrapper(`/api/matrimony/${node_id}/unmarried_members`);
  console.log('Result from getEligibleFamilyMembers ', result);
  return result;
}

export async function getAllGautras() {
  const result = await getApiWrapper(`/api/gautras`);
  console.log('Result from getAllGautras ', result);
  return result;
}

export async function createMatrimonyProfile(data) {
  const result = await postApiWrapper(`/api/matrimony/profile`, data);
  console.log('Result from createMatrimonyProfile', result);
  return result;
}

export async function getAllFamilyMemberProfiles(matrimonialId) {
  const result = await getApiWrapper(`/api/matrimony/existing_family_members/${matrimonialId}`);
  console.log('Result from getAllFamilyMemberProfiles', result);
  return result;
}

export async function getMatrimonialProfilesByMobileNo(contactNumbers) {
  const result = await getApiWrapper(`/api/matrimony/search?mobiles=${contactNumbers.join(',')}`);
  console.log('Result from getMatrimonialProfilesByMobileNo', result);
  return result;
}

export async function createFamilyMemberProfile(data) {
  const result = await postApiWrapper(`/api/matrimony/family_member_profile/create`, data);
  console.log('Result from createFamilyMemberProfile', result);
  return result;
}

export async function updateFamilyMemberProfile(matrimonialId, memberNodeId, data) {
  const result = await postApiWrapper(`/api/matrimony/family_member_profile/${matrimonialId}/${memberNodeId}`, data);
  console.log('Result from updateFamilyMemberProfile', result);
  return result;
}

export async function updateIndependentFamilyMemberProfile(familyMemberId, data) {
  const result = await postApiWrapper(`/api/matrimony/family_member_profile/${familyMemberId}`, data);
  console.log('Result from updateIndependentFamilyMemberProfile', result);
  return result;
}

export async function deleteFamilyMemberProfile(memberProfileId) {
  const result = await deleteApiWrapper(`/api/matrimony/family_member_profile/${memberProfileId}/delete`);
  console.log('Result from deleteFamilyMemberProfile', result);
  return result;
}

export const getMatrimonyPresignedUrl = async (data) => {
  const result = await postApiWrapper(`/api/matrimony/file_upload_url`, data);
  console.log('Result from getMatrimonyPresignedUrl ', result);
  return result;
};

export const getUserMatrimonialProfile = async (node_id) => {
  const result = await getApiWrapper(`/api/matrimony/profile_of/${node_id}`);
  console.log('Result from getUserMatrimonialProfile ', result);
  return result;
};

export const getMatrimonialProfileById = async (matrimonialId) => {
  const result = await getApiWrapper(`/api/matrimony/profile/${matrimonialId}`);
  console.log('Result from getMatrimonialProfileById ', result);
  return result;
};

export const updateMatrimonialProfile = async (matrimonialId, data) => {
  const result = await postApiWrapper(`/api/matrimony/profile/${matrimonialId}/update`, data);
  console.log('Result from updateMatrimonialProfile ', result);
  return result;
};

export const getMatchingProfile = async (matrimonialId) => {
  const result = await getApiWrapper(`/api/matrimony/matching_profiles/${matrimonialId}`);
  console.log('Result from getMatchingProfile ', result);
  return result;
};

export const getInfoOfSelectedUser = async (graphNodeId) => {
  const result = await getApiWrapper(`/api/users/${graphNodeId}`);
  console.log('Result from getInfoOfSelectedUser ', result);
  return result;
};
